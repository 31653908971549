/* eslint-disable max-len */
import { computed } from 'vue'
import { defineStore } from 'pinia'

const useUserStore = defineStore('user', () => {
  const currentUser = Object.freeze({
    roles: [],
    permissions: [],
    name: '訪客',
    avatarUrl: window._statementdog_global_variable_.currentUser.avatar_url || '',
    id: '',
    experiments: {},
    confirmedGuidanceList: window._statementdog_global_variable_.currentUser.confirmed_guidance_list || [],
    uninspectedNotificationsCount: window._statementdog_global_variable_.currentUser.uninspected_notifications_count || 0,
    isSignedIn: window._statementdog_global_variable_.currentUser.is_signed_in || false,
    ...window._statementdog_global_variable_.currentUser,
  })

  const abTest = computed(() => (experiment) => currentUser.experiments[experiment])

  const isNewMobileNaviTestGroup = computed(() => {
    if (!currentUser.isSignedIn) {
      return false
    }

    return abTest.value('20250313_new_mobile_navi_v5_signed_in_for_new_user') === 'test_group' || currentUser.roles.includes('admin_dev')
  })

  const isStockMovesExplanarionTestGroup = computed(() => {
    if (!currentUser.isSignedIn) {
      return false
    }

    return false
  })

  return {
    currentUser,
    isSignedIn: currentUser.isSignedIn,
    abTest,
    isNewMobileNaviTestGroup,
    isStockMovesExplanarionTestGroup,
  }
})

export default useUserStore
